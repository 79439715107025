import React from 'react';
import { graphql } from 'gatsby';
import { Container, Box, SimpleGrid, Flex } from '@chakra-ui/react';

import CanonicalLink from 'timelesstime-ui/components/canonical-link';
import SEO from 'timelesstime-ui/components/seo';
import { ServiceLeadInHeader } from 'timelesstime-ui/components/lead-in-header';
import Html from 'timelesstime-ui/components/html';
import NewsletterSignup from 'timelesstime-ui/components/newsletter-signup';
import BlockQuote from 'timelesstime-ui/components/block-quote';
import CallMeBack from 'timelesstime-ui/components/call-me-back';
import Heading from 'timelesstime-ui/components/heading';
import Jumbotron from 'timelesstime-ui/components/jumbotron';
import PageLayout from '../components/layout';
import ServiceStructuredData from '../components/structured-data/service';

const ServicePage = ({
  data: {
    service,
    service: { quotes },
  },
}) => (
  <PageLayout
    leadInHeader={
      <ServiceLeadInHeader
        heading={service.heading || service.title}
        crumbs={[
          {
            url: '/services/',
            title: 'Services',
          },
          {
            url: `/services/${service.slug}/`,
            title: service.title,
          },
        ]}
      />
    }
  >
    <SEO
      title={service.title}
      keywords={service.fields.keywords}
      description={service.metaDescription}
      canonicalPath={service.fields.path}
      thumbnail={service.jsonLdThumbnailImage}
    />
    <ServiceStructuredData
      slug={service.slug}
      title={service.title}
      serviceType={service.serviceType}
      description={service.metaDescription}
      caseStudies={service.allCaseStudies || []}
      featuredImage={service.jsonLdFeaturedImage}
    />

    <Container maxW="container.lg">
      <Box mt={4}>
        <CanonicalLink color="orange.500" to="/services/">
          back to all services
        </CanonicalLink>
      </Box>
      <Box as="header" mt={4} mb={4}>
        <Heading as="h1">{service.title}</Heading>
      </Box>

      <Html
        source={service.fields.contentHtml}
        headerLevelStart={1}
        mt={4}
        mb={8}
      />

      {quotes?.length > 1 && (
        <Box textAlign="center" my={12}>
          <CallMeBack
            size="md"
            callBackText="Do you want help from our team of experts? Get in touch"
          />
        </Box>
      )}

      {quotes?.length > 0 && (
        <>
          {quotes?.length > 0 && (
            <>
              <Heading as="h2">
                Here&apos;s what some of our clients said about us
              </Heading>

              <SimpleGrid
                as="section"
                columns={[1, 1, 2]}
                spacingX={8}
                spacingY={[8, 8, 4]}
                mt={8}
              >
                {quotes.map((quote) => (
                  <BlockQuote
                    key={quote.id}
                    text={quote?.text || ''}
                    person={quote?.person || ''}
                    position={quote?.position || ''}
                    company={quote?.company || ''}
                  />
                ))}
              </SimpleGrid>
            </>
          )}
        </>
      )}
    </Container>

    <Box as="footer" textAlign="center" my={12}>
      <CallMeBack
        size="md"
        callBackText="Call us today and speak to one of our experts"
      />
    </Box>

    <Jumbotron as="aside" py={8} bg="tt.darkBlue">
      <NewsletterSignup />
    </Jumbotron>
  </PageLayout>
);

export const query = graphql`
  query ServiceSinglePageBySlug($slug: String!) {
    service: contentfulService(slug: { eq: $slug }) {
      id
      title
      heading
      slug
      serviceType
      createdAt
      updatedAt
      metaDescription
      featuredImage {
        ...FeaturedImage
      }
      jsonLdThumbnailImage: featuredImage {
        ...JsonLdThumbnailImage
      }
      jsonLdFeaturedImage: featuredImage {
        ...JsonLdFeaturedImage
      }
      allCaseStudies: case_study {
        id
        slug
      }
      quotes {
        id
        person
        position
        company
        text {
          internal {
            content
          }
        }
      }
      fields {
        path
        url
        writtenAt
        isNew
        keywords
        contentHtml
      }
    }
  }
`;

export default ServicePage;
